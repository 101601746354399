<template>
  <div id="policyMatch">
    <div class="policyMatch_in">
      <el-form>
        <!-- 公司的基本信息 -->
        <div class="companyInfo">
          <div class="info_title">企业基本信息</div>
          <div class="companyinfo_front">
            <el-form-item>
              <template #label>
                <span class="cominfolabel">单位名称</span>
              </template>
              <el-input v-model="BasicInfo.CompanyInfo.CompanyName" disabled></el-input>
            </el-form-item>
            <el-form-item>
              <template #label>
                <span class="cominfolabel">信用代码</span>
              </template>
              <el-input v-model="BasicInfo.CompanyInfo.TaxpayerIdentityNumber" disabled></el-input>
            </el-form-item>
            <el-form-item>
              <template #label>
                <span class="cominfolabel">注册地址</span>
              </template>
              <el-input v-model="BasicInfo.CompanyInfo.RegAddressExtend" disabled></el-input>
            </el-form-item>
            <el-form-item>
              <template #label>
                <span class="cominfolabel">成立时间</span>
              </template>
              <el-input  prefix-icon="el-icon-date" v-model="BasicInfo.CompanyInfo.Regdate" disabled></el-input>
            </el-form-item>
            <el-form-item >
              <template #label>
                <span class="cominfolabel">联系人</span>
              </template>
              <el-input  placeholder="联系人姓名" v-model="BasicInfo.CompanyInfo.ContactorName"></el-input>
            </el-form-item>
            <el-form-item>
              <template #label>
                <span class="cominfolabel">手机号码</span>
              </template>
              <el-input  placeholder="联系号码" v-model="BasicInfo.CompanyInfo.ContactorPhone"></el-input>
            </el-form-item>
            <el-form-item>
              <template #label>
                <span class="cominfolabel">行业名称</span>
              </template>
              <!-- <el-input v-model="BasicInfo.CompanyInfo.IndustryID"></el-input> -->
              <el-select v-model="BasicInfo.CompanyInfo.IndustryID" placeholder="请选择" clearable>
                <el-option
                  v-for="item in IndustryList"
                  :key="item.DictID"
                  :label="item.DictName"
                  :value="item.DictName">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="companyInfo_behind">
            <el-form-item>
              <template #label>
                <span class="cominfolabel">产业领域</span>
              </template>
              
              <el-select
              clearable
              v-model="chooseIndustryField1" placeholder="请选择" @change="chooseInduF1()">
                <el-option
                  v-for="item in IndustryField1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  >
                </el-option>
              </el-select>
              <el-select 
              clearable
              v-model="chooseIndustryField2" placeholder="请选择" @change="chooseInduF2()">
                <el-option
                  v-for="item in IndustryField2"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
              <div class="thirdbox">
                <el-select v-model="chooseIndustryField3"
                clearable
                multiple
              
                placeholder="请选择"
                @change="chooseInduF3()">
                  <el-option
                    v-for="item in IndustryField3"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </div>
              
            </el-form-item>
            <el-form-item>
              <template #label>
                <span class="cominfolabel">技术领域同上</span>
              </template>
              <div class="radiobox">
                <el-radio v-model="BasicInfo.CompanyInfo.IsTechnologyField" label="0">是</el-radio>
                <el-radio v-model="BasicInfo.CompanyInfo.IsTechnologyField" label="1">否</el-radio>
              </div>
            </el-form-item>
            <el-form-item>
              <template #label>
                <span class="cominfolabel">企业规模</span>
              </template>
              <div class="radiobox">
                <el-radio 
                v-for="(item,idx) in CompanySizeList"
                :key="idx"
                v-model="BasicInfo.CompanyInfo.CompanySizeID" :label="item.DictID">{{item.DictName}}</el-radio>
              </div>
            </el-form-item>
            <el-form-item>
              <template #label>
                <span class="cominfolabel">机构类型</span>
              </template>
              <div class="radiobox">
                <el-radio
                v-for="(item,idx) in InstitutionTypeList"
                :key="idx"
                v-model="BasicInfo.CompanyInfo.InstitutionTypeID" :label="item.DictID">{{item.DictName}}</el-radio>
              </div>
            </el-form-item>
            <el-form-item>
              <template #label>
                <span class="cominfolabel">独立法人资格</span>
              </template>
              <div class="radiobox">
                <el-radio v-model="BasicInfo.CompanyInfo.IsIndependent" label="0">是</el-radio>
                <el-radio v-model="BasicInfo.CompanyInfo.IsIndependent" label="1">否</el-radio>
              </div>
            </el-form-item>
          </div>
        </div>
        <!-- 财务情况 -->
        <div class="pecuniaryCondition">
          <div class="info_title">财务情况</div>
          <div class="pec_condition">
            <!-- 近三年循环 -->
            <div class="p_c_section"
            v-for="(item,idx) in BasicInfo.PecuniaryCondition" :key='idx'>
              <el-form-item>
                <template #label>
                  <span class="pec_c_label">{{item.year}}年研发费用：</span>
                </template>
                <el-input v-model="item.research_amount" type="number" 
                :precision = '2'
                @keydown.native="channelInputLimit"
                @blur="NumbertoFixedcheck(item.research_amount,idx,'research_amount')">
                  <template #append>万元</template>
                </el-input>
              </el-form-item>
              <el-form-item>
                <template #label>
                  <span class="pec_c_label">{{item.year}}年销售收入：</span>
                </template>
                <el-input v-model="item.sales_amount" type="number" 
                @keydown.native="channelInputLimit"
                @blur="NumbertoFixedcheck(item.sales_amount,idx,'sales_amount')">
                  <template #append>万元</template>
                </el-input>
              </el-form-item>
              <el-form-item>
                <template #label>
                  <span class="pec_c_label">{{item.year}}年境内研发费用：</span>
                </template>
                <el-input v-model="item.within_the_territory_research_amount"
                type="number" 
                @keydown.native="channelInputLimit"
                @blur="NumbertoFixedcheck(item.within_the_territory_research_amount,idx,'within_the_territory_research_amount')"
                ><template #append>万元</template></el-input>
              </el-form-item>
              <el-form-item>
                <template #label>
                  <span class="pec_c_label">{{item.year}}年-净利润：</span>
                </template>
                <el-input v-model="item.net_profit_amount"
                type="number" 
                @keydown.native="channelInputLimit"
                @blur="NumbertoFixedcheck(item.net_profit_amount,idx,'net_profit_amount')"
                ><template #append>万元</template></el-input>
              </el-form-item>
              <el-form-item>
                <template #label>
                  <span class="pec_c_label">{{item.year}}年资产总额：</span>
                </template>
                <el-input v-model="item.total_assets"
                type="number" 
                @keydown.native="channelInputLimit"
                @blur="NumbertoFixedcheck(item.total_assets,idx,'total_assets')"
                ><template #append>万元</template></el-input>
              </el-form-item>
              <el-form-item>
                <template #label>
                  <span class="pec_c_label">{{item.year}}年仪器设备原始值：</span>
                </template>
                <el-input v-model="item.equipment_original_price"
                type="number" 
                @keydown.native="channelInputLimit"
                @blur="NumbertoFixedcheck(item.equipment_original_price,idx,'equipment_original_price')"
                ><template #append>万元</template></el-input>
              </el-form-item>
                <el-form-item>
                <template #label>
                  <span class="pec_c_label">{{item.year}}年高新产品收入：</span>
                </template>
                <el-input v-model="item.hightech_product_revenue"
                type="number" 
                @keydown.native="channelInputLimit"
                @blur="NumbertoFixedcheck(item.hightech_product_revenue,idx,'hightech_product_revenue')"
                ><template #append>万元</template></el-input>
              </el-form-item>
            </div>
          </div>
        </div>
        <!-- 人员情况 -->
        <div class="staffCondition">
          <div class="info_title">人员情况</div>
          <div class="staffcontent">
            <el-form-item>
              <template #label>
                <span class="staffcond_label">人员数量：</span>
              </template>
              <el-input v-model="BasicInfo.StaffCondition.TotalStaffNum"
              @keydown.native="channelInputLimit2"
              type="number"
              @blur="NumbertoInt(BasicInfo.StaffCondition.TotalStaffNum,'TotalStaffNum')"
              ><template #append>人</template></el-input>
            </el-form-item>
            <el-form-item>
              <template #label>
                <span class="staffcond_label">科技人员数量：</span>
              </template>
              <el-input v-model="BasicInfo.StaffCondition.TechStaffNum"
              @keydown.native="channelInputLimit2"
              type="number"
              @blur="NumbertoInt(BasicInfo.StaffCondition.TechStaffNum,'TechStaffNum')"
              ><template #append>人</template></el-input>
            </el-form-item>
            <el-form-item>
              <template #label>
                <span class="staffcond_label">专职研发人员数量：</span>
              </template>
              <el-input v-model="BasicInfo.StaffCondition.DevlopStaffNum"
              @keydown.native="channelInputLimit2"
              type="number"
              @blur="NumbertoInt(BasicInfo.StaffCondition.DevlopStaffNum,'DevlopStaffNum')"
              ><template #append>人</template></el-input>
            </el-form-item>
            <el-form-item>
              <template #label>
                <span class="staffcond_label">缴纳社保的非股东员工数：</span>
              </template>
              <el-input v-model="BasicInfo.StaffCondition.SocialSecurityNotShareHolderStaffNum"
              type="number"
              @keydown.native="channelInputLimit2"
              @blur="NumbertoInt(BasicInfo.StaffCondition.SocialSecurityNotShareHolderStaffNum,'SocialSecurityNotShareHolderStaffNum')"
              ><template #append>人</template></el-input>
            </el-form-item>
          </div>
        </div>
        <!-- 自主知识产权 -->
        <div class="ZSCQCondition">
          <div class="info_title">自主知识产权</div>
          <div class="zscqcontent">
            <div class="zscq_section" 
            v-for="(item,index) in BasicInfo.ZSCQCondition"
            :key="index"
            >
              <div class="zscq_title">{{item.year}}-知识产权数量</div>
              <div class="zscq_li">
                <el-form-item>
                  <template #label>
                    <span class="zscq_label">软件著作权：</span>
                  </template>
                  <el-input v-model="item.RJZZQnum"
                  @keydown.native="channelInputLimit2"
                  type="number"
                  @blur="NumbertoInt1(item.RJZZQnum,index,'RJZZQnum')"
                  ><template #append>个</template></el-input>
                </el-form-item>
              </div>
              <div class="zscq_li">
                <el-form-item>
                  <template #label>
                    <span class="zscq_label">发明专利数量：</span>
                  </template>
                  <el-input v-model="item.FMZLnum"
                  @keydown.native="channelInputLimit2"
                  type="number"
                  @blur="NumbertoInt1(item.FMZLnum,index,'FMZLnum')"
                  ><template #append>个</template></el-input>
                </el-form-item>
              </div>
              <div class="zscq_li">
                <el-form-item>
                  <template #label>
                    <span class="zscq_label">实用新型专利数量：</span>
                  </template>
                  <el-input v-model="item.SYXXZLnum"
                  @keydown.native="channelInputLimit2"
                  type="number"
                  @blur="NumbertoInt1(item.SYXXZLnum,index,'SYXXZLnum')"
                  ><template #append>个</template></el-input>
                </el-form-item>
              </div>
              <div class="zscq_li">
                <el-form-item>
                  <template #label>
                    <span class="zscq_label">外观专利数量：</span>
                  </template>
                  <el-input v-model="item.WGZLnum"
                  @keydown.native="channelInputLimit2"
                  type="number"
                  @blur="NumbertoInt1(BasicInfo.ZSCQCondition.WGZLnum,index,'WGZLnum')"
                  ><template #append>个</template></el-input>
                </el-form-item>
              </div>

            </div>
          </div>
        </div>
        <!-- 企业资质 -->
        <div class="EnterpriseQualification">
          <div class="info_title">企业资质</div>
          <div class="enterpriseQual_content">
            <el-form-item>
              <template #label>
                <span class="e_quali_label">是否为规模以上企业：</span>
              </template>
              <div class="radiobox">
                <el-radio v-model="BasicInfo.EnterpriseQualification.IsSizeOverCompany" label="0">是</el-radio>
                <el-radio v-model="BasicInfo.EnterpriseQualification.IsSizeOverCompany" label="1">否</el-radio>
              </div>
            </el-form-item>
            <el-form-item>
              <template #label>
                <span class="e_quali_label">是否获得市级以上项目：</span>
              </template>
              <div class="radiobox">
                <el-radio v-model="BasicInfo.EnterpriseQualification.IsOwnDevelopPartment" label="0">是</el-radio>
                <el-radio v-model="BasicInfo.EnterpriseQualification.IsOwnDevelopPartment" label="1">否</el-radio>
              </div>
            </el-form-item>
            <el-form-item>
              <template #label>
                <span class="e_quali_label">是否建有企业研发机构：</span>
              </template>
              <div class="radiobox">
                <el-radio v-model="BasicInfo.EnterpriseQualification.IsDevelopOrModernServiceCompany" label="0">是</el-radio>
                <el-radio v-model="BasicInfo.EnterpriseQualification.IsDevelopOrModernServiceCompany" label="1">否</el-radio>
              </div>
            </el-form-item>
            <el-form-item>
              <template #label>
                <span class="e_quali_label">已获得的企业资质：</span>
              </template>
              <div class="choose_btn"><el-button @click="EnterpriseQuailificationListVisible = true">点击选择</el-button></div>
              <div class="choose_tag">
                <el-tag
                :key="tag"
                v-for="tag in [1,2,3]"
                closable
                :disable-transitions="false"
                  >
                标签
              </el-tag>
              </div>
            </el-form-item>
          </div>
        </div>
        <!-- 已获得的市级以上项目 -->
        <div class="OverCityItem">
          <div class="info_title">已获得市级以上项目</div>
          <div class="overcityitem_content">
            <el-form-item>
              <template #label>
                <span class="oci_label">是否获得市级以上项目：</span>
              </template>
              <div class="radiobox">
                <el-radio v-model="BasicInfo.IsOwnCityItem" label="0">是</el-radio>
                <el-radio v-model="BasicInfo.IsOwnCityItem" label="1">否</el-radio>
              </div>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <!-- 选择企业资质弹出框 -->
    <el-dialog
      title="提示"
      :visible.sync="EnterpriseQuailificationListVisible"
      width="40%"
      >
      <div class="ep_q_content">
        <el-tabs v-model="active_EP_Q" @tab-click="choosetabclick">
          <el-tab-pane v-for="(item,index) in EP_Q_tabList" :key="index" :label="item" :name="item">
            <el-checkbox-group v-model="chooseEnterpriseQualification">
            <div class="ep_q_List">
              <div class="ep_q_item" v-for="(item,index) in EnterpriseQualificationList" :key="index" v-show="item.Mark == active_EP_Q">
                <el-checkbox v-model="item.checked">{{item.DictName}}</el-checkbox>
              </div>
            </div>
            </el-checkbox-group>
          </el-tab-pane>
        </el-tabs>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="EnterpriseQuailificationListVisible = false">取 消</el-button>
        <el-button type="primary" @click="EnterpriseQuailificationListVisible = false">确 定</el-button>
      </span>
    </el-dialog>

    <footer-page></footer-page>
  </div>
</template>

<script>
import footerPage from '@/components/common/footer/footerPage.vue';
export default {
  name:'policyMatch',
  data () {
    return {
      //小数正则
      // toFixedString:'^(([1-9]{1}\d*)|(0{1}))(\.\d{2})$',
      BasicInfo:{
        CompanyInfo:{
          CompanyName:'',//企业名称
          TaxpayerIdentityNumber:'',//税号
          RegAddressID:'',//注册地址
          RegAddressExtend:'',//注册详细地址
          Regdate:'',//注册日期
          ContactorName:'',//联系人
          ContactorPhone:'',//联系人电话
          IndustryID:'',//行业名称
          IndustryFieldID:'',//产业领域ID
          IndustryFieldName:'',//产业领域名称
          IsTechnologyField:'',//是否是技术领域
          CompanySizeID:'',//企业规模
          InstitutionTypeID:'',//机构类型
          IsIndependent:'',//是否独立法人资格
        },//公司基本信息
        PecuniaryCondition:[
          {   //今年
            year:new Date().getFullYear(),  //年份
            research_amount:0.00, // 研发费用
            sales_amount:0.00,// 销售金额
            within_the_territory_research_amount:0.00,  // 境内研发费用
            net_profit_amount:0.00,//净利润
            total_assets:0.00,//资产总额
            research_area:0.00,//研发面积
            equipment_original_price:0.00,//设备原始价格
            hightech_product_revenue:0.00//高新产品收入
          },      
          {   //过去一年
            year:new Date().getFullYear()-1,  //年份
            research_amount:0.00, // 研发费用
            sales_amount:0.00,// 销售金额
            within_the_territory_research_amount:0.00,  // 境内研发费用
            net_profit_amount:0.00,//净利润
            total_assets:0.00,//资产总额
            research_area:0.00,//研发面积
            equipment_original_price:0.00,//设备原始价格
            hightech_product_revenue:0.00//高新产品收入
          },
          {   //过去两年
            year:new Date().getFullYear()-2,  //年份
            research_amount:0.00, // 研发费用
            sales_amount:0.00,// 销售金额
            within_the_territory_research_amount:0.00,  // 境内研发费用
            net_profit_amount:0.00,//净利润
            total_assets:0.00,//资产总额
            research_area:0.00,//研发面积
            equipment_original_price:0.00,//设备原始价格
            hightech_product_revenue:0.00//高新产品收入
          },
        ],// 财务情况
        StaffCondition:{
          TotalStaffNum:0,//人员数量
          TechStaffNum:0,//科技人员数量
          DevlopStaffNum:0,//专职研发人员数量
          SocialSecurityNotShareHolderStaffNum:0//缴纳社保的非股东员工数
        },//人员情况
        ZSCQCondition:[
          {
            year:new Date().getFullYear(),//年份
            RJZZQnum:0,//软件著作权数量
            FMZLnum:0,//发明专利数量
            SYXXZLnum:0,//实用新型专利数量
            WGZLnum:0,//外观专利数量
          },
          {
            year:new Date().getFullYear()-1,//年份
            RJZZQnum:0,//软件著作权数量
            FMZLnum:0,//发明专利数量
            SYXXZLnum:0,//实用新型专利数量
            WGZLnum:0,//外观专利数量
          },
          {
            year:new Date().getFullYear()-2,//年份
            RJZZQnum:0,//软件著作权数量
            FMZLnum:0,//发明专利数量
            SYXXZLnum:0,//实用新型专利数量
            WGZLnum:0,//外观专利数量
          },
        ],//知识产权情况
        EnterpriseQualification:{
          IsSizeOverCompany:'', //是否为规模以上企业  0/1
          IsOwnDevelopPartment:'', //是否建有企业研发机构 0/1
          IsDevelopOrModernServiceCompany:'', //科技型企业或现代服务业企业 0/1
          HasCompanyQulifications:'', //已获得的企业资质
        },//企业资质
        IsOwnCityItem:'', //是否获得市级以上项目 0/1
      },//基本信息
      IndustryList:[],//行业名称
      chooseIndustryId:'',//选中的行业名称
      
      IndustryField1:[],//产业领域名称列表1
      chooseIndustryField1:'',//选中的领域1
      IndustryField2:[],//产业领域名称列表2
      chooseIndustryField2:'',//选中的领域1

      IndustryField3:[],//产业领域名称列表3
      chooseIndustryField3:'',//选中的领域3

      CompanySizeList:[],//公司规模列表
      InstitutionTypeList:[],//机构类型列表

      EnterpriseQualificationList:[], // 企业资质
      chooseEnterpriseQualification:[], //  已拥有的企业资质
      EnterpriseQuailificationListVisible:false, //控制企业资质弹框可见 默认不可见
      EP_Q_tabList:['国家级','省级','市级'],
      active_EP_Q:'国家级',
      //test数据  ---删
      checked:true
    }
  },
  components:{
    footerPage
  },
  computed:{
    userinfo(){
      return this.$store.state.userinfo
    },
    // isEP_Q_checked(){
    //   this.EnterpriseQualificationList.forEach((item,index) => {
    //     if(this.BasicInfo.EnterpriseQualification.HasCompanyQulifications.indexOf(item.DictID) != -1){
    //       this.EnterpriseQualificationList[index].checked = true
    //     }
    //   })
    //   return this.EnterpriseQualificationList
    // }
  },
  created(){
    this.init()
  },
  methods:{
    //初始化数据
    init(){
      //请求认证信息
      this.$axios('/API/Get_UserAuthentication',{
        Version:'',
        CompanyUserFLnkID:this.userinfo.UserGuid,
        JZToken:this.userinfo.TokenCode,
        UserGuid:this.userinfo.UserGuid
      }).then( res => {
        if(res.status == 200){
          this.BasicInfo.CompanyInfo.CompanyName = res.data[0].CompanyName
          this.BasicInfo.CompanyInfo.TaxpayerIdentityNumber = res.data[0].TaxpayerIdentityNumber
          this.BasicInfo.CompanyInfo.RegAddressID = res.data[0].RegAddressID
          this.BasicInfo.CompanyInfo.RegAddressExtend = res.data[0].RegAddressName
          this.BasicInfo.CompanyInfo.Regdate = res.data[0].RegdateDisplay
          this.BasicInfo.CompanyInfo.ContactorName = res.data[0].ArtificialPersonName
          this.BasicInfo.CompanyInfo.ContactorPhone = this.userinfo.MobileNo
          //获取企业信息
          this.$axios('/API/Get_CompanyInfo',{
            Version:'',
            TaxpayerIdentityNumber:res.data[0].TaxpayerIdentityNumber,
            JZToken:this.userinfo.TokenCode,
            UserGuid: this.userinfo.UserGuid
          }).then(res => {
            console.log(res)
            if(res.status == 200){
              this.BasicInfo.EnterpriseQualification.HasCompanyQulifications = res.company.HasCompanyQulifications.split(',')

              this.EnterpriseQualificationList.forEach((item,index) => {
                if(this.BasicInfo.EnterpriseQualification.HasCompanyQulifications.indexOf(item.DictID) != -1){
                  this.chooseEnterpriseQualification.push(item)
                  item.checked = true
                }else{
                  item.checked = false
                }
              })
            }

          })

        }
      })
      //企业规模接口
      this.$axios('/API/GetDict',{
        Version:'',
        DictTypeID:'QYXX_QYGM',
      }).then( res => {
        if(res.status == 200)
          this.CompanySizeList = res.data
        
      })
      //机构类型
      this.$axios('/API/GetDict',{
        Version:'',
        DictTypeID:'QYXX_QYGM',
      }).then( res => {
        if(res.status == 200){
          this.InstitutionTypeList = res.data
        }
      })
      //行业名称
      this.$axios('/API/GetDict',{
        Version:'',
        DictTypeID:'QYXX_HY',
      }).then( res => {
        if(res.status == 200){
          this.IndustryList = res.data
        }
      })
      //产业领域ID 产业领域名称
      this.$axios('/API/GetIndustryFieldDictList',{
        Version:'',
        DictTypeID:'QYXX_CYLY'
      }).then( res => {
        if(res.status == 200){
          this.IndustryField1 = res.data
        }
      })
      //获得的企业资质
      this.$axios('/API/GetDict',{
        Version:'',
        DictTypeID:'QYXX_QYZZ'
      }).then( res => {
        console.log(res)
        if(res.status == 200){
          this.EnterpriseQualificationList = res.data
        }
      })
    },
    //选择行业
    chooseInduF1(){
      console.log(this.chooseIndustryField1)
      this.IndustryField1.forEach( item => {
        if(this.chooseIndustryField1 == item.value){
          this.IndustryField2 = item.children
        }
      })
      this.chooseIndustryField2 = ''
      this.chooseIndustryField3 = ''
    },
    chooseInduF2(){
      console.log(this.chooseIndustryField2)
      this.IndustryField2.forEach(item => {
        if(this.chooseIndustryField2 == item.value){
          this.IndustryField3 = item.children
        }
      })
      this.chooseIndustryField3 = ''
    },
    chooseInduF3(){
      this.BasicInfo.CompanyInfo.IndustryFieldID = this.chooseIndustryField3.join('+')
      let temparr = []
      this.IndustryField3.forEach( (item,index) => {
        if(this.chooseIndustryField3.indexOf(item.value) > -1)
          temparr.push(item.label)
      })
      this.BasicInfo.CompanyInfo.IndustryFieldName = temparr.join('+')
    },
    //保留两位小数
    NumbertoFixedcheck(value,idx,str){
      this.BasicInfo.PecuniaryCondition.forEach((item,i) => {
        if(idx == i){
          if(!isNaN(parseFloat(value))){
            this.BasicInfo.PecuniaryCondition[i][str] =+parseFloat(value).toFixed(2)
          }else{
            this.BasicInfo.PecuniaryCondition[i][str] = 0.00
          }
        }
      })
    },
    //人员情况只能为整数
    NumbertoInt(value,str){
      if(!isNaN(parseInt(value))){
        this.BasicInfo.StaffCondition[str] = parseInt(value)
      }else{
        this.BasicInfo.StaffCondition[str] = 0
      }
    },
    //知识产权只能为整数
    NumbertoInt1(value,idx,str){
      this.BasicInfo.ZSCQCondition.forEach( (item,i) => {
        if(idx == i){
          if(!isNaN(parseInt(value))){
            this.BasicInfo.ZSCQCondition[i][str] = parseInt(value)
          }else{
            this.BasicInfo.ZSCQCondition[i][str] = 0
          }
        }
      })
    },
    //限制输入e 
    channelInputLimit(e){
      let key = e.key
      if(key === 'e' || key === '-'){
        e.returnValue = false
        return false
      }
      return true
    },
    //限制e和小数点
    channelInputLimit2(e){
      let key = e.key
      if(key === 'e' || key == '.' ||  key === '-'){
        e.returnValue = false
      }
      return true
    },
    //选择企业资质切换
    choosetabclick(e){
      this.active_EP_Q = e.label
    },
    //选择选中框
    clickcheckbox(index){
      console.log(index)
    }

  }

}
</script>

<style scoped lang='scss'>
    ::v-deep input::-webkit-outer-spin-button,
    ::v-deep input::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
      margin: 0;
    }
  @media (min-width: 1201px){
    @import './policyMatch.scss';
  }
  @media (max-width: 1200px){
    @import './policyMatch_phone.scss';
  }
</style>
